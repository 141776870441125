<template>
    <b-container fluid class="p-0">
        <b-row v-for="message in tree.messageFlow"
               class="message-row"
               :class="`${message.side}${message.type == 'add' ? ' message-row-add' : ''}${message.type != 'add' || message.visible ? ' visible' : ''}`">
            <b-col class="p-0">
                <b-row v-if="message.type.startsWith('log')" align-v="center" class="my-2" :class="message.type">
                    <b-col class="message-log-separator"></b-col>
                    <b-col cols="12" md="auto" class="message-log" @click="message.type == 'log-loop' ? gotoTarget(message.target) : null">
                        <i class="fas" :class="message.icon"></i>
                        {{message.content}}
                        <i v-if="message.type == 'log-loop'" class="fas fa-trash-alt loop-action" title="Remover retorno de fluxo" @click.stop="removeLoop(message.object)"></i>
                    </b-col>
                    <b-col class="message-log-separator"></b-col>
                </b-row>
                <MessageBox v-else-if="message.type == 'message'"
                            :key="message.object ? `message-box-${message.object._uid}` : null"
                            :message="typeof message.content === 'function' ? message.content() : message.content"
                            :side="message.side"
                            :editFunction="message.object ? () => editMessageObject(message.object, message) : null"
                            :deleteFunction="message.object? method => deleteMessage(method, message) : null"
                            :closeFunction="message.object ? resetManager : null"
                            :changeMessage="message.object ? content => message.object.descricao = content : null"
                            :writing="manager.properties == message.object"
                            :ref="message.object ? `message-box-${message.object._uid}` : null" />
                <b-row v-else-if="message.type == 'options'">
                    <b-col>
                        <b-container fluid>
                            <b-row class="px-1">
                                <b-col v-for="option in message.options"
                                       cols="12" md="auto"
                                       class="message-option-container"
                                       :class="option.isOpen ? 'active' : ''">
                                    <b-row>
                                        <b-col @click="openOption(option)"
                                               class="message-option px-3 py-2 mr-3"
                                               :class="manager.properties == option.object ? 'writing' : ''">
                                            <b-row>
                                                <b-col cols="12" md="auto">
                                                    <i v-if="option.object.ClientesComplementoOpcaoId" class="fas fa-database mr-1"></i>
                                                    {{option.label}}
                                                </b-col>
                                                <b-col v-if="manager.properties == option.object" cols="12" md="auto" class="pl-0">
                                                    <i class="fas fa-check" title="Ok" @click.stop="resetManager"></i>
                                                </b-col>
                                                <b-col v-if="manager.properties != option.object" class="pl-0">
                                                    <i title="Editar" @click.stop="editMessageObject(option.object, option)" class="fas fa-pen"></i>
                                                </b-col>
                                                <b-col class="pl-0" v-if="message.object.tipo != 4">
                                                    <i title="Excluir" @click.stop="deleteOption(option.object)" class="fas fa-trash-alt"></i>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                    <b-row align-h="center">
                                        <b-col cols="12" md="auto" class="mr-3">
                                            <i class="fas fa-chevron-down"></i>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12" md="auto" class="message-option-container" v-if="message.object.tipo != 4">
                                    <b-row>
                                        <b-col @click="addOption(message.object, $event.target)" class="message-option message-option-add px-3 py-2 mr-3">
                                            <b-row>
                                                <b-col cols="12" md="auto">
                                                    <i class="fas fa-plus"></i>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-col>
                </b-row>
                <MessageBox v-else-if="message.type == 'add'"
                            :key="getSymbol()"
                            side="left"
                            :object="message"
                            :afterAddMessage="object => { editThenFocusMessageObject(object) }" />
            </b-col>
        </b-row>
        <ModalMessageListSelector ref="message-list-selector" :getMessagesList="() => tree.getFluxoDePerguntas(tree.perguntaInicial)" />
        <ModalArchivedFlowSelector ref="archived-flow-selector" :getArchivedFlowList="() => tree.getPerguntasAusentes()" />
    </b-container>
</template>
<script>
    import MessageBox from "@/components/arvore-decisoes/MessageBox";
    import HtmlEditor from "@/components/inputs/HtmlEditor";
    import ModalMessageListSelector from "@/components/arvore-decisoes/Modals/MessageListSelector";
    import ModalArchivedFlowSelector from "@/components/arvore-decisoes/Modals/ArchivedFlowSelector";
    import ChatPerguntaObjectMaps from "@/assets/js/object-maps/arvore-decisoes/chat-pergunta";
    import { ConstructorsNames } from "@/assets/js/models/arvore-decisoes";
    export default {
        name: "MessageFlow",
        components: {
            MessageBox,
            HtmlEditor,
            ModalMessageListSelector,
            ModalArchivedFlowSelector
        },
        props: {
            tree: null,
            manager: null
        },
        inject: ["resetManager"],
        data() {
            return {
                chatPerguntaObjectMap: null,
                chatPerguntaOpcaoObjectMap: null
            }
        },
        provide() {
            return {
                hasMessageList: this.hasMessageList,
                openMessageListSelector: this.openModalMessageListSelector,
                hasArchivedFlow: this.hasArchivedFlow,
                openArchivedFlowSelector: this.openModalArchivedFlowSelector
            }
        },
        watch: {
            "manager.properties": {
                handler() {
                    this.updateMessageAddVisibility();
                }
            },
            "tree.messageFlow": {
                handler() {
                    this.updateMessageAddVisibility();
                }
            }
        },
        methods: {
            getSymbol() {
                return Symbol();
            },
            confirm(message, action) {
                if (!action) return;
                this.$bvModal.msgBoxConfirm(message ?? "Para continuar, clique em confirmar.", {
                    title: "Atenção",
                    size: "md",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Confirmar",
                    cancelTitle: "Cancelar"
                }).then(confirm => {
                    if (confirm) action();
                });
            },
            updateMessageAddVisibility() {
                this.tree.messageFlow.filter(item => item.constructor.name == ConstructorsNames.MessageAdd).forEach(item => {
                    if (item.prevMessageObject == this.manager.properties || item == this.tree.messageFlow.slice(-1)[0]) {
                        item.visible = true;
                        return;
                    }
                    item.visible = false;
                });
            },
            openOption(option) {
                option.open();
                if (this.manager.properties.constructor.name == ConstructorsNames.ChatPerguntaOpcao && this.manager.properties.chat_pergunta == option.object.chat_pergunta) this.editMessageObject(option.object, option);
            },
            addOption(object, target) {
                let icon = target;
                if (![...icon.classList].includes("fa-plus")) icon = icon.querySelector(".fas.fa-plus");
                if (!icon) return;
                icon.classList.remove("fa-plus");
                icon.classList.add("fa-circle-notch");
                icon.classList.add("fa-spin");
                object.addOpcao().then(opcao => {
                    icon.classList.remove("fa-circle-notch");
                    icon.classList.remove("fa-spin");
                    icon.classList.add("fa-plus");
                    this.editMessageObject(opcao, this.tree.messageFlow.slice(-1)[0].options.slice(-1)[0]);
                });
            },
            editMessageObject(object, element) {
                if (object == this.manager.properties) return;
                let currentManager = { ...this.manager },
                    type = object.constructor.name,
                    backTo = () => {
                        if (this.tree.id != "new") object.commitChanges();
                        Object.keys(this.manager).forEach(key => {
                            this.manager[key] = currentManager[key];
                        });
                    };
                if (this.tree.id != "new" && this.manager.properties && this.manager.properties.commitChanges) this.manager.properties.commitChanges();
                this.manager.back = backTo;
                this.manager.name = () => $(`<span>${type == ConstructorsNames.ChatPergunta ? "Mensagem" : "Opção"}: ${object.descricao || '_'}</span>`).text();
                this.manager.properties = object;
                this.manager.map = type == ConstructorsNames.ChatPergunta ? this.chatPerguntaObjectMap : this.chatPerguntaOpcaoObjectMap;
                if (this.tree.id == "new") {
                    this.manager.actions = {
                        apply: {
                            label: "Ok",
                            variant: "success",
                            action: () => {
                                window.getSelection().removeAllRanges();
                                this.resetManager();
                            }
                        }
                    };
                } else {
                    this.manager.actions = {
                        delete: {
                            label: "Excluir",
                            variant: "light",
                            action: () => {
                                if (type == ConstructorsNames.ChatPergunta) this.deleteMessage("delMessage", object);
                                if (type == ConstructorsNames.ChatPerguntaOpcao) this.deleteOption(object);
                            }
                        },
                        save: {
                            label: "Salvar",
                            variant: "success",
                            action: button => {
                                button.disabled = true;
                                button.innerHTML = `<i class="fas fa-circle-notch fa-spin"></i>&nbsp;&nbsp;Salvando...`;
                                object.commitChanges().then(() => {
                                    button.disabled = false;
                                    button.innerHTML = `<i class="fas fa-check"></i>&nbsp;&nbsp;Salvo!`;
                                    setTimeout(() => {
                                        button.innerHTML = `Salvar`;
                                    }, 2500);
                                });
                            }
                        }
                    };
                }
                if (element && type == ConstructorsNames.ChatPerguntaOpcao && !element.isOpen) element.open();
            },
            editThenFocusMessageObject(object) {
                if (object.constructor.name != ConstructorsNames.ChatPergunta) return;
                this.editMessageObject(object);
                let editor = this.$refs[`message-box-${object._uid}`][0].$el,
                    textNode = editor.querySelector("[contenteditable]").firstChild,
                    selector = window.getSelection(),
                    range = document.createRange();
                range.setStart(textNode, 0);
                range.setEnd(textNode, textNode.textContent.length);
                selector.removeAllRanges();
                selector.addRange(range);
                setTimeout(() => {
                    editor.scrollIntoView({ behavior: "smooth", block: "nearest" });
                }, 300);
            },
            deleteMessage(method, message) {
                let methods = {
                    delMessage: () => {
                        this.confirm("A mensagem será excluída deste ponto e de todos os outros a qual ela estiver referenciada. Deseja continuar?", () => {
                            let proxima_pergunta = message.object.proxima_pergunta,
                                delMessage = () => {
									[
										...this.tree.perguntas.filter(item => item.proxima_pergunta == message.object.id),
										...[].concat(...this.tree.perguntas.map(item => item.opcoes.filter(opcao => opcao.proxima_pergunta == message.object.id)).filter(item => item.length > 0))
									].forEach(item => {
										item.proxima_pergunta = proxima_pergunta;
										if (this.tree.id != "new") item.commitChanges();
									});
									if (this.tree.pergunta_inicial == message.object.id) {
										this.tree.pergunta_inicial = proxima_pergunta;
										if (this.tree.id != "new") this.tree.commitChanges();
									}
									if (this.manager.properties == message.object) this.resetManager();
                                    this.tree.perguntas.splice(this.tree.perguntas.indexOf(message.object), 1);
                                };
                            if (this.tree.id != "new") {
                                message.object.delete().then(() => {
                                    message.id = "deleted";
                                }).then(delMessage);
                                return;
                            }
                            delMessage();
                        });
                    },
                    archiveMessageFlow: () => {
                        this.confirm([this.$createElement("div", {
                            domProps: {
								innerHTML: "A mensagem será desvinculada do ponto especificado, bem como toda a sua sequência.<br />Caso não haja nenhum outro vínculo à mensagem, a sequência será arquivada e poderá ser recuperada.<br /><br />Deseja continuar?"
                            }
                        })], () => {
							let messageIndex = this.tree.messageFlow.indexOf(message);
							let prevObj = messageIndex > 0 ? this.tree.messageFlow[this.tree.messageFlow.indexOf(message) - 1] : null;
                            if (prevObj && prevObj.type == "add") prevObj = prevObj.prevMessageObject;
                            else if (prevObj && prevObj.type == "options") prevObj = prevObj.options.find(option => option.isOpen).object;
                            else if (prevObj) prevObj = prevObj.object;

                            if (prevObj) {
                                prevObj.proxima_pergunta = null;
                                if (this.tree.id != "new") prevObj.commitChanges();
                                return;
                            }
							this.tree.pergunta_inicial = null;
							if (this.tree.id != "new") this.tree.commitChanges();
                        });
                    }
                };
                if (typeof methods[method] == "function") methods[method]();
            },
            deleteOption(option) {
                this.confirm("A opção será excluída. Deseja continuar?", () => {
                    let removeElement = () => {
                        if (this.manager.properties == option) this.resetManager();
                        let chat_pergunta = option.chat_pergunta;
                        chat_pergunta.opcoes.splice(chat_pergunta.opcoes.indexOf(option), 1);
                        chat_pergunta.arvore.reportChangeOf(chat_pergunta, "opcao-excluida");
                    };
                    if (this.tree.id != "new") {
                        option.delete().then(() => {
                            option.id = "deleted";
                        }).then(removeElement);
                        return;
                    }
                    removeElement();
                });
            },
            gotoTarget(target) {
                this.$refs[`message-box-${target._uid}`][0].$el.scrollIntoView({ behavior: "smooth", block: "nearest" });
            },
            removeLoop(object) {
                this.confirm("O retorno de fluxo será removido. Deseja continuar?", () => {
                    object.proxima_pergunta = null;
                    if (this.tree.id != "new") object.commitChanges();
                });
            },
            hasMessageList() {
                return this.tree.messageFlow.map(item => item.type == "message").length > 1;
            },
            openModalMessageListSelector(onSelect, selectedMessage, disabledMessages) {
                if (this.$refs["message-list-selector"]) this.$refs["message-list-selector"].open(onSelect, selectedMessage, disabledMessages);
            },
            hasArchivedFlow() {
                return this.tree.getPerguntasAusentes().length > 0;
            },
            openModalArchivedFlowSelector(onSelect) {
                if (this.$refs["archived-flow-selector"]) this.$refs["archived-flow-selector"].open(onSelect);
            }
        },
        created() {
            (async maps => {
                this.chatPerguntaObjectMap = await maps.getChatPerguntaObjectMap();
                this.chatPerguntaOpcaoObjectMap = await maps.getChatPerguntaOpcaoObjectMap();
            })(ChatPerguntaObjectMaps);
            this.updateMessageAddVisibility();
        }
    }
</script>
<style scoped>
    .message-row-add {
        visibility: hidden;
        max-height: 0;
        opacity: 0;
        transition: all ease-in-out .2s;
    }

        .message-row-add.visible {
            visibility: visible;
            max-height: 80px;
            opacity: 1;
        }

    .message-log {
        background-color: var(--cinza-3);
        color: var(--cinza-5);
        border: 2px var(--cinza-3) solid;
        font-size: 14px;
    }

        .message-log > i.fas {
            margin-right: 3px;
        }

    .message-log-separator {
        background-color: var(--cinza-3);
        height: 2px;
    }

    .log-loop .message-log {
        cursor: pointer;
    }

    .message-log .loop-action {
        margin-left: 10px;
    }

    .message-option {
        border: 1px var(--cor-primaria-cliente) solid;
        color: var(--cor-primaria-cliente);
        cursor: default;
        transition: all ease-in-out .3s;
        font-size: 14px;
    }

        .message-option:hover {
            background-color: var(--cor-primaria-cliente);
            color: #fff;
        }

        .message-option.writing {
            background-color: #faff8e !important;
            border-color: #faff8e;
            color: var(--cor-primaria-cliente) !important;
        }

            .message-option.writing:hover {
                border-color: var(--cor-primaria-cliente);
            }

        .message-option i.fas {
            cursor: pointer;
            font-size: 12px;
        }

        .message-option i.fas.fa-database {
            cursor: default;
        }

    .message-option-add {
        cursor: pointer;
    }

    .message-option-container.active .message-option {
        background-color: var(--cor-primaria-cliente);
        color: #fff;
        cursor: default;
    }

        .message-option-container.active .message-option.writing {
            border-color: var(--cor-primaria-cliente);
        }

    .message-option-container .fas.fa-chevron-down {
        color: transparent;
        transition: all ease-in-out .3s;
    }

    .message-option-container.active .fas.fa-chevron-down {
        color: var(--cor-primaria-cliente);
    }
</style>
<style>
    .message-row.left:not(.message-row-add) + .message-row.message-row-add + .message-row.left, .message-row.right + .message-row.right {
        margin-top: -15px;
    }

        .message-row.left:not(.message-row-add) + .message-row.message-row-add + .message-row.left .message-box:before, .message-row.right + .message-row.right .message-box:before, .message-row.left + .message-row.message-row-add .message-box:before {
            visibility: hidden;
        }

    .message-row.left + .message-row.message-row-add.visible {
        margin: -15px !important;
    }

    .message-row + .message-row.left .message-box.add-message .message-box-message:after {
        content: " aqui";
    }
</style>