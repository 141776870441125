<template>
	<b-container fluid class="properties-manager-container ml-1 pb-2">
		<b-row class="py-2 px-3">
			<b class="text-truncate">
				<i v-if="back" class="fas fa-arrow-left mr-2 properties-manager-back" @click="back()"></i>
				{{[title, objectName].filter(item => item != null).join(" - ")}}
			</b>
		</b-row>
		<b-row class="properties-manager-fields py-1 px-3 app-scroll-custom">
			<ObjectMapEditor :object="properties" :map="map" />
		</b-row>
		<b-row class="action-buttons py-1 px-3 my-0 pt-3">
			<b-button v-for="item in Object.keys(actions)"
					  :ref="`action-${item}`"
					  class="ml-3 mb-2 rounded-0"
					  :variant="actions[item].variant"
					  @click="typeof actions[item].action == 'function' ? actions[item].action($refs[`action-${item}`][0]) : null">
				{{actions[item].label}}
			</b-button>
		</b-row>
	</b-container>
</template>
<script>
	import ObjectMapEditor from "@/components/arvore-decisoes/ObjectMapEditor";
	export default {
		name: "PropertiesManager",
		computed: {
			objectName() {
				return typeof this.name === "function" ? this.name() : this.name;
			}
		},
		components: {
			ObjectMapEditor
		},
		props: {
			title: {
				default: "Propriedades"
			},
			name: null,
			properties: null,
			map: null,
			back: null,
			actions: null
		}
	}
</script>
<style scoped>
	.properties-manager-container {
		background-color: #fff;
		min-width: 350px;
		width: 25vw;
	}

	.properties-manager-fields {
		overflow-y: auto;
		max-height: calc(100vh - 300px);
	}

	.properties-manager-back {
		cursor: pointer;
	}

	.tree-property {
		margin-bottom: 10px;
	}

		.tree-property label {
			display: block;
			color: var(--cinza-5);
			font-weight: bold;
			font-size: 12px;
			margin-bottom: 0;
		}

	.array-component {
		overflow-y: auto;
		max-height: 250px;
		background-color: var(--cinza-2);
		border: 1px var(--cinza-4) solid;
	}

		.array-component input[type=checkbox] {
			display: none;
		}

		.array-component label {
			cursor: pointer;
			padding: 5px 7px;
			margin: 0;
			transition: all ease-in-out .3s;
		}

			.array-component label:hover {
				background-color: #fff;
			}

			.array-component label:before {
				content: "\f0c8";
				font-family: "Font Awesome 5 Free";
				font-weight: 500;
				margin-right: 10px;
			}

		.array-component input[type=checkbox]:checked + label {
			background-color: var(--cinza-3);
		}

			.array-component input[type=checkbox]:checked + label:before {
				content: "\f14a";
				font-weight: 600;
			}

	.collapsible-component {
		background-color: var(--cinza-2);
		border: 1px var(--cinza-4) solid;
	}

		.collapsible-component > p {
			margin: 0;
			padding: 5px 7px;
			cursor: pointer;
			transition: all ease-in-out.3s;
		}

			.collapsible-component > p > span {
				display: inline-block;
				font-size: 12px;
				line-height: 12px;
				height: 12px;
				color: var(--cinza-5);
				font-weight: bold;
				overflow-y: hidden;
			}

				.collapsible-component > p > span:before {
					content: "\f105";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					margin-right: 10px;
					color: #000;
				}

			.collapsible-component > p:hover {
				background-color: #fff;
			}

	.action-buttons {
		justify-content: flex-end;
		border-top: 2px var(--cinza-3) solid;
	}
</style>