<template>
	<b-container ref="menu" 
				 class="context-menu app-scroll-custom p-0" 
				 :class="visible ? 'visible' : null"
				 @click.stop
				 :style="useFixedPosition ? 'position: fixed' : ''">
		<b-row v-for="action in Object.keys(actions)"
			   class="context-menu-item px-2"
			   @mousedown="runActionOf(actions[action])"
			   :title="actions[action].title"
			   :class="actions[action].disabled ?'disabled' : ''">
			<b-col cols="1">
				<i v-if="actions[action].fasIcon" class="fas mr-1" :class="actions[action].fasIcon"></i>
				<b-icon v-else-if="actions[action].bIcon" :icon="actions[action].bIcon" class="mr-1"></b-icon>
			</b-col>
			<b-col class="text-truncate px-1">{{actions[action].label}}</b-col>
		</b-row>
	</b-container>
</template>
<script>
	export default {
		name: "ContextMenu",
		props: {
			actions: {
				default: {}
			},
			useFixedPosition: {
				default: false
			}
		},
		data() {
			return {
				visible: false,
				mousePosition: { x: 0, y: 0 }
			};
		},
		methods: {
			runActionOf(object) {
				if (object.disabled) return;
				if (typeof object.action == "function") object.action();
				this.hide();
			},
			show() {
				this.visible = false;
				let position = {
					x: this.mousePosition.x + 14,
					y: this.mousePosition.y + 14
				};
				this.$refs.menu.style.width = `${Math.max(...Object.keys(this.actions).map(item => this.actions[item].label.length)) * 6 + 40}px`;
				let boundingClientRect = this.$refs.menu.parentElement.getBoundingClientRect();
				setTimeout(() => {
					this.$refs.menu.style.left = `${this.useFixedPosition ? boundingClientRect.left + position.x : position.x}px`;
					this.$refs.menu.style.top = `${this.useFixedPosition ? boundingClientRect.top + position.y : position.y}px`;
					this.visible = true;
				}, 10);
			},
			hide() {
				this.visible = false;
			},
			toggle() {
				if (this.visible) {
					this.hide();
					return;
				}
				this.show();
			}
		},
		mounted() {
			let resetPosition = () => {
				if (!this.$refs.menu) return;
				this.mousePosition = {
					x: this.$refs.menu.parentElement.offsetWidth * .5,
					y: this.$refs.menu.parentElement.offsetHeight * .5
				};
			};
			this.$refs.menu.parentElement.addEventListener("mousemove", e => {
				this.mousePosition = (rect => ({
					x: e.clientX - rect.left,
					y: e.clientY - rect.top
				}))(this.$refs.menu.parentElement.getBoundingClientRect());
			}, false);
			this.$refs.menu.parentElement.addEventListener("mouseout", resetPosition, false);
			resetPosition();
		}
	}
</script>
<style scoped>
	.context-menu {
		width: auto;
		position: absolute;
		visibility: hidden;
		z-index: 1000;
		background-color: #fff;
		box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
		cursor: default;
		max-height: 250px;
		overflow-x: hidden;
	}

		.context-menu.visible {
			visibility: visible;
			animation: show-context-menu ease-in .15s;
		}

	.context-menu-item {
		padding: 3px 5px;
		white-space: nowrap;
		color: var(--cinza-5);
		font-size: 12px;
		text-align: left;
		color: #2c3e50;
	}

		.context-menu-item.disabled {
			color: var(--cinza-4);
		}

		.context-menu-item:not(.disabled):hover {
			background-color: var(--cor-primaria-cliente);
			color: #fff;
		}

	@keyframes show-context-menu {
		0% {
			margin-top: -20px;
			opacity: 0;
		}

		50% {
			opacity: 1;
		}

		100% {
			margin-top: 0;
		}
	}
</style>