<template>
	<b-modal id="message-list-selector" title="Selecione uma mensagem..." size="lg" @hide="reset">
		<b-container class="app-scroll-custom">
			<b class="message-list-selector-title">
				<i class="fas fa-arrow-left" v-if="backProperties" title="Voltar" @click="back"></i>
				{{title}}
			</b>
			<b-container v-for="message in messagesList"
						 :class="`message-list-selector-message${message == selectedMessage ? ' selected' : ''}${disabledMessages.includes(message) ? ' disabled' : ''}`"
						 @click="disabledMessages.includes(message) ? null : selectedMessage = message">
				<b-row>
					<b-col cols="12" md="auto" class="m-0 p-0 mr-3">
						<i :class="`fas ${message == selectedMessage ? 'fa-check-circle' : 'fa-circle'}`"></i>
					</b-col>
					<b-col v-html="message.descricao"
						   class="message-list-selector-message-descricao"></b-col>
				</b-row>
			</b-container>
			<b-container v-if="optionsList" class="mt-2">
				<b-row>
					<b-col cols="12" md="auto" class="m-0 p-0 mr-3"></b-col>
					<b-col class="message-list-selector-options-container p-0 ml-3">
						<b-button v-for="item in optionsList"
								  :key="getSymbol()"
								  size="sm"
								  variant="info"
								  @click="update(item.opcao, item.getPerguntas())"
								  class="rounded-0 ml-2 mb-2">
							{{item.opcao}}
						</b-button>
					</b-col>
				</b-row>
			</b-container>
		</b-container>
		<template #modal-footer="{ cancel }">
			<b-button variant="light" @click="cancel()" class="rounded-0">Cancelar</b-button>
			<b-button variant="success"
					  @click="confirm"
					  class="rounded-0"
					  :disabled="!selectedMessage || !messagesList.includes(selectedMessage)">
				Confirmar
			</b-button>
		</template>
	</b-modal>
</template>
<script>
	export default {
		name: "ModalMessageListSelector",
		props: {
			getMessagesList: null
		},
		data() {
			return {
				title: null,
				messagesList: null,
				optionsList: null,
				backProperties: null,
				originalSelection: null,
				selectedMessage: null,
				disabledMessages: [],
				onSelect: null
			}
		},
		methods: {
			getSymbol() {
				return Symbol();
			},
			open(onSelect, selectedMessage, disabledMessages) {
				if (typeof this.getMessagesList != "function") return;
				this.update("Início", this.getMessagesList());
				this.onSelect = onSelect;
				this.originalSelection = selectedMessage;
				this.selectedMessage = selectedMessage;
				this.disabledMessages = disabledMessages;
				this.$bvModal.show("message-list-selector");
			},
			update(title, list) {
				if (this.title) {
					this.backProperties = {
						title: this.title,
						messagesList: this.messagesList,
						optionsList: this.optionsList,
						backProperties: this.backProperties,
						originalSelection: this.originalSelection,
						selectedMessage: this.selectedMessage,
						disabledMessages: this.disabledMessages,
						onSelect: this.onSelect
					};
					this.title += ` / ${title}`;
				} else this.title = title;
				this.messagesList = list.filter(item => !item.getPerguntas);
				this.optionsList = list.filter(item => item.getPerguntas);
			},
			back() {
				Object.keys(this.backProperties).forEach(key => {
					if (key != "backProperties") this[key] = this.backProperties[key];
				});
				this.backProperties = this.backProperties.backProperties;
			},
			confirm() {
				let changeFlow = () => {
					if (typeof this.onSelect == "function") {
						this.onSelect(this.selectedMessage);
						this.$bvModal.hide("message-list-selector");
					}
				}
				if (this.originalSelection && this.selectedMessage != this.originalSelection) {
					this.$bvModal.msgBoxConfirm("O fluxo atual será substituído pelo fluxo à partir da mensagem selecionada.", {
						title: "Atenção",
						size: "md",
						buttonSize: "sm",
						okVariant: "danger",
						okTitle: "Entendi, confirmar substituição",
						cancelTitle: "Cancelar"
					}).then(confirm => {
						if (confirm) changeFlow();
					});
					return;
				} else if (!this.originalSelection) changeFlow();
			},
			reset() {
				setTimeout(() => {
					this.title = null;
					this.messagesList = null;
					this.optionsList = null;
					this.backProperties = null;
					this.originalSelection = null;
					this.selectedMessage = null;
					this.disabledMessages = [];
					this.onSelect = null;
				}, 300);
			}
		}
	}
</script>
<style scoped>
	#message-list-selector .app-scroll-custom {
		max-height: 70vh;
	}

	.message-list-selector-title {
		display: block;
		margin-bottom: 10px;
		user-select: none;
	}

		.message-list-selector-title > i.fas.fa-arrow-left {
			margin-right: 5px;
			cursor: pointer;
			font-size: 14px;
		}

	.message-list-selector-message i.fas {
		color: var(--cinza-4);
		font-weight: 500;
		cursor: pointer;
	}

	.message-list-selector-message-descricao {
		background-color: var(--cinza-3);
		border: 1px var(--cinza-4) solid;
		cursor: pointer;
		padding: 5px 10px;
		font-size: 13px;
	}

	.message-list-selector-message.selected .message-list-selector-message-descricao {
		background-color: var(--cor-primaria-cliente);
		border-color: var(--cor-primaria-cliente);
		color: #fff;
		cursor: default;
	}

	.message-list-selector-message.selected i.fas {
		color: var(--cor-primaria-cliente);
		font-weight: 900;
		cursor: default;
	}

	.message-list-selector-message.disabled .message-list-selector-message-descricao {
		background-color: var(--cinza-2);
		border-color: var(--cinza-3);
		color: var(--cinza-4);
		cursor: default;
	}

	.message-list-selector-message.disabled i.fas {
		color: var(--cinza-3);
		cursor: default;
	}

	.message-list-selector-message + .message-list-selector-message:before {
		content: "";
		display: block;
		background-color: var(--cinza-4);
		width: 2px;
		height: 20px;
		margin-left: calc(50% - 1px);
	}

	.message-list-selector-options-container {
		text-align: right;
	}

	.message-list-selector-loop-container {
		text-align: center;
	}

		.message-list-selector-loop-container > i.fas.fa-infinity {
			color: var(--cinza-4);
		}
</style>