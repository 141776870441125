<template>
	<b-container fluid>
		<b-row>
			<Breadcrumb :titulo="breadcrumb.title"
						:items="breadcrumb.items"
						:backgroundColor="breadcrumb.backgroundColor"
						:color="breadcrumb.color" />
		</b-row>
		<b-row class="mx-0 my-3">
			<b-col id="tree-manager-dashboard">
				<b-row id="trees-openers">
					<div v-for="tree in trees"
						 @click="currentTree == tree.id ? 0 : openTree(tree.id)"
						 :class="currentTree == tree.id ? 'active' : ''"
						 class="px-4 py-2">
						<div v-if="tree.id == 'new'" class="trees-add" title="Criar árvore">
							<i class="fas fa-plus"></i>
						</div>
						<span v-else class="text-truncate">{{tree.nome || '_'}}</span>
					</div>
				</b-row>
				<b-row id="trees-managers" class="px-1 py-1">
					<b-container v-if="isTreeReady" fluid class="tree-manager-container my-2">
						<b-row class="h-100">
							<b-col class="h-100">
								<b-container fluid class="message-flow-container px-3 py-2 h-100">
									<b-row>
										<b-col>
											<b>Fluxo de Mensagens</b>
										</b-col>
									</b-row>
									<b-row class="message-flow-perguntas py-1 px-3 mr-1 mb-2 app-scroll-custom">
										<b-col class="mx-3">
											<MessageFlow :tree="tree" :manager="manager" />
										</b-col>
									</b-row>
								</b-container>
							</b-col>
							<b-col cols="12" md="auto">
								<PropertiesManager :name="manager.name"
												   :properties="manager.properties"
												   :map="manager.map"
												   :back="manager.back"
												   :actions="manager.actions" />
							</b-col>
						</b-row>
					</b-container>
					<b-container v-else>
						<b-row align-v="center" align-h="center" class="h-100">
							<b-col cols="12" md="auto">
								<i class="fas fa-sitemap tree-loading"></i>
							</b-col>
						</b-row>
					</b-container>
				</b-row>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
	import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
	import MessageFlow from "@/components/arvore-decisoes/MessageFlow";
	import PropertiesManager from "@/components/arvore-decisoes/PropertiesManager";
	import getArvoreDecisoesObjectMap from "@/assets/js/object-maps/arvore-decisoes/arvore-decisoes";
	import ArvoreDecisoes from "@/assets/js/models/arvore-decisoes";
	import ArvoreDecisoesProvider from "@/assets/js/providers/arvore-decisoes";

	export default {
		name: "ArvoreDecisoesPage",
		components: {
			Breadcrumb,
			MessageFlow,
			PropertiesManager
		},
		data() {
			return {
				breadcrumb: {
					title: "Árvore de Decisões",
					backgroundColor: "#c5e13f",
					color: "#707070",
					items: [
						{ classe: "fas fa-project-diagram", texto: "Árvore de Decisões", link: "" }
					]
				},
				currentTree: null,
				isTreeReady: false,
				isCreatingProcess: false,
				trees: [],
				manager: {
					name: null,
					properties: null,
					map: null,
					back: null,
					actions: null
				},
				arvoreDecisoesObjectMap: null,
			}
		},
		computed: {
			tree() {
				if (this.currentTree == null) return null;
				return this.getTree(this.currentTree);
			}
		},
		provide() {
			return {
				resetManager: () => {
					if (this.currentTree != "new" && this.manager.properties && this.manager.properties.commitChanges) this.manager.properties.commitChanges();
					this.editTree(this.getTree(this.currentTree));
				}
			}
		},
		watch: {
			currentTree(treeId) {
				if (this.$route.params.id != treeId) this.$router.replace({ path: `/arvore-decisoes/${treeId}` });
				if (!this.isCreatingProcess) this.editTree(this.getTree(treeId));
			}
		},
		methods: {
			openTree(treeId) {
				this.isTreeReady = false;
				if (this.trees.length == 0) return;
				if (!this.getTree(treeId)) throw "openTree(treeId) error: invalid `treeId`.";
				if (this.tree) this.tree.reset();
				this.currentTree = treeId;
				this.tree.getUpdatedData().then(() => {
					this.isTreeReady = true;
				});
			},
			getTree(treeId) {
				return this.trees.filter(tree => tree.id == treeId)[0];
			},
			editTree(tree) {
				this.manager.name = () => `Árvore: ${tree.nome || "_"}`;
				this.manager.properties = tree;
				this.manager.map = this.arvoreDecisoesObjectMap;
				this.manager.back = null;
				if (tree.id == "new") {
					this.manager.actions = {
						create: {
							label: "Salvar",
							variant: "success",
							action: () => {
								if (tree.nome.trim().length == 0) {
									this.$bvModal.msgBoxOk("Por favor, informe um nome para a nova árvore.", {
										title: "Atenção"
									});
									return;
								}
								this.isTreeReady = false;
								this.isCreatingProcess = true;
								tree.commitChanges().then(() => {
									this.currentTree = tree.id;
									this.trees.push(new ArvoreDecisoes({
										id: "new",
										nome: ""
									}));
									Promise.all(tree.perguntas.map(pergunta => pergunta.commitChanges())).then(() => {
										Promise.all([
											...tree.perguntas.filter(pergunta => pergunta.proxima_pergunta),
											...[].concat(...tree.perguntas.map(pergunta => pergunta.opcoes.filter(opcao => opcao.proxima_pergunta))),
											tree

										].map(item => item.commitChanges())).then(() => {
											this.editTree(tree);
											this.isTreeReady = true;
											this.isCreatingProcess = false;
										});
									});
								});
							}
						}
					};
					return;
				}
				this.manager.actions = {
					delete: {
						label: "Excluir",
						variant: "light",
						action: () => {
							this.$bvModal.msgBoxConfirm(`A árvore de decisões ${tree.nome} será excluída. Deseja continuar?`, {
								title: "Atenção",
								size: "md",
								buttonSize: "sm",
								okVariant: "danger",
								okTitle: "Confirmar",
								cancelTitle: "Cancelar"
							}).then(confirm => {
								if (confirm) this.deleteTree(tree);
							});
						}
					},
					save: {
						label: "Salvar",
						variant: "success",
						action: button => {
							button.disabled = true;
							button.innerHTML = `<i class="fas fa-circle-notch fa-spin"></i>&nbsp;&nbsp;Salvando...`;
							tree.commitChanges().then(() => {
								button.disabled = false;
								button.innerHTML = `<i class="fas fa-check"></i>&nbsp;&nbsp;Salvo!`;
								setTimeout(() => {
									button.innerHTML = `Salvar`;
								}, 2500);
							});
						}
					}
				};
			},
			deleteTree(tree) {
				tree.delete().then(() => {
					let index = this.trees.indexOf(tree);
					this.trees.splice(index, 1);
					if (this.trees[index] && this.trees[index].id != "new") this.openTree(this.trees[index].id);
					else if (this.trees[index - 1]) this.openTree(this.trees[index - 1].id);
					else this.openTree(this.trees[0].id);
				});
			}
		},
		created() {
			Promise.all([
				getArvoreDecisoesObjectMap().then(map => {
					this.arvoreDecisoesObjectMap = map;
				}),
				ArvoreDecisoes.getListed(true).then(trees => {
					this.trees = trees;
				})
			]).then(() => {
				let treeId = this.$route.params.id;
				if (!this.getTree(treeId)) treeId = this.trees[0].id;
				this.openTree(treeId);
			});
		},
		destroyed() {
			ArvoreDecisoesProvider.clear();
		}
	}
</script>
<style scoped>
	#trees-openers > div {
		background-color: var(--cinza-2);
		cursor: pointer;
		color: var(--cinza-5);
		border-right: 1px var(--cinza-3) solid;
	}

		#trees-openers > div.active {
			background-color: var(--cinza-3);
			cursor: default;
			vertical-align: middle;
		}

			#trees-openers > div.active > span {
				text-shadow: .3px 0 var(--cinza-5), -.3px 0 var(--cinza-5);
			}

			#trees-openers > div.active .trees-add .fas.fa-plus {
				color: var(--cor-primaria-cliente);
			}

		#trees-openers > div:hover > span {
			text-shadow: .3px 0 var(--cinza-5), -.3px 0 var(--cinza-5);
		}

		#trees-openers > div .trees-add {
			color: var(--cinza-4);
		}

		#trees-openers > div:first-child .trees-add {
			background-color: var(--cinza-3);
			cursor: default;
		}

			#trees-openers > div:hover .trees-add > i.fas.fa-plus, #trees-openers > div:first-child .trees-add > i.fas.fa-plus {
				color: var(--cor-primaria-cliente);
			}

			#trees-openers > div:first-child .trees-add:after {
				content: "Criar árvore";
				margin-left: 7px;
				color: var(--cinza-5);
			}

	#tree-manager-dashboard {
		margin: 0 auto;
	}

	#trees-managers {
		background-color: var(--cinza-3);
		cursor: default;
		min-height: 75vh;
	}

	.tree-manager-container {
		animation: fade-in .7s ease;
	}

	.tree-loading {
		font-size: 15vh;
		color: transparent;
		background: linear-gradient(var(--cinza-4), var(--cinza-3), var(--cinza-4));
		background-size: 200% 200%;
		background-clip: text;
		animation: fade-in .7s ease, translucent 2s linear infinite;
	}

	.message-flow-container {
		background-color: #fff;
	}

	.message-flow-perguntas {
		overflow-y: auto;
		height: calc(100% - 24px);
		max-height: calc(100vh - 250px);
	}

	@keyframes translucent {
		from {
			background-position: 0 200%;
		}

		to {
			background-position: 0 -200%;
		}
	}

	@keyframes fade-in {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
</style>